import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import PrivateBetaBanner from '../components/private-beta-banner'
import SEO from '../components/seo'
import styles from './creators.module.scss'
import BrandRelationship1Img from '../components/creators/brand-relationship-1'
import BrandRelationship2Img from '../components/creators/brand-relationship-2'
import UploadAndEarnImg from '../components/creators/upload-and-earn'
import BePartOfACommunity1Img from '../components/creators/be-part-of-a-community-1'
import BePartOfACommunity2Img from '../components/creators/be-part-of-a-community-2'

const CreatorsPage = () => (
  <Layout>
    <SEO title='Creators' />

    <div className='container mx-auto mb-12'>
      <div className='flex flex-col sm:flex-row justify-between sm:items-center'>
        <div className='px-4 lg:px-8'>
          <h1 className='text-2xl sm:text-4xl'>
            For Creators
          </h1>
        </div>
      </div>

      <div className='mt-8 md:mt-16 block flex flex-col sm:flex-row justify-evenly items-center'>
        <div className='px-4 sm:w-1/2'>
          <h2>Relationships with top brands</h2>
          <p className='mt-6 mb-8 md:my-2'>Instant access to some of the largest labels in Australia and around the world. Access promotions and deals with brands easily through MODE</p>
        </div>
        <div className={styles.twoImgs}>
          <div className={styles.img1}>
            <BrandRelationship1Img />
          </div>
          <div className={styles.img2}>
            <BrandRelationship2Img />
          </div>
        </div>
      </div>

      <div className='mt-32 md:mt-16 block flex flex-col sm:flex-row-reverse justify-evenly items-center'>
        <div className='px-4'>
          <h2>Upload and earn</h2>
          <p className='mt-6 mb-8 md:my-2'>Earn up to the following amount per post</p>
          <div className='mt-8'>
            <table class="w-full table-auto">
              <thead>
                <tr>
                  <th>Followers</th>
                  <th>Creator Earnings/Post</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1K-5K</td>
                  <td>$86.8</td>
                </tr>
                <tr>
                  <td>5K-20K</td>
                  <td>$150.66</td>
                </tr>
                <tr>
                  <td>20K-100K</td>
                  <td>$333.25</td>
                </tr>
                <tr>
                  <td>100K-1M</td>
                  <td>$651.38</td>
                </tr>
                <tr>
                  <td>1M+</td>
                  <td>$651.38+</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.img}>
          <UploadAndEarnImg />
        </div>
      </div>

      <div className='mt-32 md:mt-24 block flex flex-col sm:flex-row justify-evenly items-center'>
        <div className='px-4 sm:w-1/2'>
          <h2>Be part of a community</h2>
          <p className='mt-6 mb-8 md:my-2'>Our ambassadors get exclusive invites to special events, gifts, first-look at new lines and technology</p>
        </div>
        <div className={styles.twoImgs}>
          <div className={styles.img1}>
            <BePartOfACommunity1Img />
          </div>
          <div className={styles.img2}>
            <BePartOfACommunity2Img />
          </div>
        </div>
      </div>
      <div className='mx-10 my-16 text-center text-sm'>
        <p>
          Still got questions? Find answers in
          {' '}
          <Link to='/faq/' className='underline font-bold'>FAQ</Link>
        </p>
      </div>
    </div>

    <PrivateBetaBanner title='Join our Private Beta' />
  </Layout>
)

export default CreatorsPage
