import React from 'react'
import ReserveYourUsernameButton from './reserve-your-username-button'
import styles from './private-beta-banner.module.scss'

const PrivateBetaBanner = ({ title }) => (
  <div className={`${styles.banner} py-12`}>
    <div className='container mx-auto px-4 text-center'>
      <div className={`${styles.title} mb-3`}>
        { title || 'Want exclusive early access?' }
      </div>
      <p className='mb-4 lg:w-1/2 mx-auto'>
        Sign up for exclusive early access to our platform and be among the first to start earning money from your outfits
      </p>
      <ReserveYourUsernameButton color='white' />
    </div>
  </div>
)

export default PrivateBetaBanner
