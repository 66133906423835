import React from 'react';
import { Link } from 'gatsby';

const ReserveYourUsernameButton = ({ color }) => {
  let btnStyles = 'bg-black hover:bg-gray-700 text-white';
  if (color === 'white') {
    btnStyles = 'bg-white hover:bg-gray-300 text-black';
  }

  return (
    <Link to='/private-beta/'>
      <button className={`${btnStyles} font-bold text-xs uppercase py-2 px-4 rounded-sm my-3`}>
        Reserve your username
      </button>
    </Link>
  );
}

export default ReserveYourUsernameButton
